 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {createClient,} from 'graphql-ws'
import ms from 'ms'
import {commitLocalUpdate} from 'relay-runtime'

import createProxyRecord from './relay/createProxyRecord'

const setConnectedStatus = (atmosphere, isConnected) => {
  commitLocalUpdate(atmosphere, (store) => {
    const root = store.getRoot()
    const viewer = root.getLinkedRecord('viewer')
    if (!viewer) {
      const tempViewer = createProxyRecord(store, 'User', {isConnected})
      root.setLinkedRecord(tempViewer, 'viewer')
    } else {
      viewer.setValue(isConnected, 'isConnected')
    }
  })
}

let firewallMessageSent = false
let recentDisconnects = [] 

export const onDisconnected = (atmosphere) => {
  setConnectedStatus(atmosphere, false)
  if (!firewallMessageSent) {
    const now = Date.now()
    recentDisconnects = recentDisconnects.filter((time) => time > now - ms('1m'))
    recentDisconnects.push(now)
    if (recentDisconnects.length >= 4) {
      firewallMessageSent = true
      atmosphere.eventEmitter.emit('addSnackbar', {
        autoDismiss: 0,
        message: 'Your internet is unstable. Behind a firewall? Contact us for support',
        key: 'firewall'
      })
      return
    }
  }
  atmosphere.eventEmitter.emit('addSnackbar', {
    autoDismiss: 0,
    message: 'You’re offline, reconnecting…',
    key: 'offline'
  })
}

export const onReconnect = (atmosphere) => {
  setConnectedStatus(atmosphere, true)
  atmosphere.eventEmitter.emit('removeSnackbar', ({key}) => key === 'offline')
}

export function createWSClient(atmosphere) {
  return new Promise((resolve, reject) => {
    const wsProtocol = window.location.protocol.replace('http', 'ws')
    const host = __PRODUCTION__
      ? window.location.host
      : `${window.location.hostname}:${__SOCKET_PORT__}`
    const url = `${wsProtocol}//${host}`
    let hasConnected = false
    let abruptlyClosed = false
    let nextId = 1
    const subscriptionClient = createClient({
      lazy: false,
      generateID: () => {
        return String(nextId++)
      },
      retryAttempts: 20,
      shouldRetry: () => {
        if (!atmosphere.authToken) return false
        return true
      },
      on: {
        connected: async (_socket, payload, _wasRetry) => {
          const {version, authToken} = payload 
          if (authToken) {
            atmosphere.setAuthToken(authToken)
          }
          const isNewVersion = version !== __APP_VERSION__
          if (isNewVersion) {
            const registration = await navigator.serviceWorker.getRegistration()
            _optionalChain([registration, 'optionalAccess', _ => _.update, 'call', _2 => _2(), 'access', _3 => _3.catch, 'call', _4 => _4(() => {
              /*ignore*/
            })])
          }
          if (abruptlyClosed) {
            // if the client abruptly closed, this is then a reconnect
            abruptlyClosed = false
            onReconnect(atmosphere)
            if (!isNewVersion) {
              // don't refetch on version bumps because the reconnect is fast & it'll strangle the server if everyone does that at once
              atmosphere.retries.forEach((retry) => retry())
            }
          }
          if (!hasConnected) {
            hasConnected = true
            resolve(subscriptionClient)
          }
          setConnectedStatus(atmosphere, true)
        },
        closed: (event) => {
          if (!hasConnected) {
            console.error('Could not connect via WebSocket')
            reject(event)
          }
          const {code, reason} = event 
          // These codes are sent from the connected hook on the server
          if ([4403, 4500].includes(code)) {
            atmosphere.invalidateSession(reason)
          }
          // non-1000 close codes are abrupt closes
          if (code !== 1000) {
            abruptlyClosed = true
            if (hasConnected) {
              onDisconnected(atmosphere)
            }
          }
          hasConnected = false
        }
      },
      url,
      connectionParams: () => {
        return {
          token: atmosphere.authToken
        }
      }
    })
  })
}
