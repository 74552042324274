 function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import EventEmitter from 'eventemitter3'

import jwtDecode from 'jwt-decode'


import {


  Environment,




  Network,

  Observable,

  RecordSource,
  RelayFeatureFlags,

  Store,


  fetchQuery
} from 'relay-runtime'




import {LocalStorageKey} from './types/constEnums'
import {createWSClient} from './utils/createWSClient'
import handlerProvider from './utils/relay/handlerProvider'
import sleep from './utils/sleep'
;(RelayFeatureFlags ).ENABLE_RELAY_CONTAINERS_SUSPENSE = false
;(RelayFeatureFlags ).ENABLE_PRECISE_TYPE_REFINEMENT = true
















const noop = () => {
  /* noop */
}

export const noopSink = {
  next: noop,
  error: noop,
  complete: noop
}

const toFormData = (
  request,
  variables,
  uploadables
) => {
  const formData = new FormData()
  formData.append('operations', JSON.stringify({docId: request.id, variables}))

  // Map the uploadable files
  const map = {}
  let i = 0
  Object.keys(uploadables).forEach((key) => {
    map[i] = [`variables.${key}`]
    formData.append(i.toString(), uploadables[key])
    i++
  })

  formData.append('map', JSON.stringify(map))
  return formData
}












const store = new Store(new RecordSource(), {gcReleaseBufferSize: 10000})

export default class Atmosphere extends Environment {
  static __initStatic() {this.getKey = (name, variables) => {
    return JSON.stringify({name, variables})
  }}
  
  __init() {this.authToken = null}
  __init2() {this.authObj = null}
  __init3() {this.querySubscriptions = []}
  __init4() {this.queryTimeouts

 = {}}
  __init5() {this.retries = new Set()}
  __init6() {this.subscriptions = {}}
  // Our server only sends a single field per subscription, but relay requires every requested field
  // This object makes the server response whole without increasing the payload size
  __init7() {this.subscriptionInterfaces = {} }
  __init8() {this.eventEmitter = new EventEmitter()}
  __init9() {this.queryCache = {} }
  // it's only null before login, so it's just a little white lie
  __init10() {this.viewerId = null}
  
  
  __init11() {this.connectWebsocketPromise = null}
  constructor() {
    super({
      store,
      handlerProvider,
      network: Network.create(noop)
    });Atmosphere.prototype.__init.call(this);Atmosphere.prototype.__init2.call(this);Atmosphere.prototype.__init3.call(this);Atmosphere.prototype.__init4.call(this);Atmosphere.prototype.__init5.call(this);Atmosphere.prototype.__init6.call(this);Atmosphere.prototype.__init7.call(this);Atmosphere.prototype.__init8.call(this);Atmosphere.prototype.__init9.call(this);Atmosphere.prototype.__init10.call(this);Atmosphere.prototype.__init11.call(this);Atmosphere.prototype.__init12.call(this);Atmosphere.prototype.__init13.call(this);Atmosphere.prototype.__init14.call(this);Atmosphere.prototype.__init15.call(this);Atmosphere.prototype.__init16.call(this);Atmosphere.prototype.__init17.call(this);Atmosphere.prototype.__init18.call(this);
    this._network = Network.create(this.fetchFunction, this.fetchOrSubscribe) 
  }

   async connectWebsocket() {
    // wait until the first and only upgrade has completed
    if (!this.connectWebsocketPromise) {
      this.connectWebsocketPromise = createWSClient(this)
      this.connectWebsocketPromise
        .then((client) => {
          this.subscriptionClient = client
        })
        .catch(() => {
          /* noop */
        })
    }
    return this.connectWebsocketPromise
  }

  __init12() {this.fetchFunction = (request, variables, cacheConfig, uploadables) => {
    const useHTTP = !!uploadables || !this.authToken || !this.subscriptionClient
    if (useHTTP) {
      const response = fetch('/graphql', {
        method: 'POST',
        headers: {
          accept: 'application/json',
          Authorization: this.authToken ? `Bearer ${this.authToken}` : '',
          ...(!uploadables && {['content-type']: 'application/json'})
        },
        body: uploadables
          ? toFormData(request, variables, uploadables)
          : JSON.stringify({
              docId: request.id,
              variables
            })
      })
      return Observable.from(response.then((data) => data.json()))
    }
    return this.fetchOrSubscribe(request, variables, cacheConfig)
  }}
  __init13() {this.fetchOrSubscribe = (
    operation,
    variables,
    _cacheConfig
  ) => {
    return Observable.create((sink) => {
      const _next = sink.next
      const _error = sink.error
      sink.error = (error) => {
        if (Array.isArray(error)) {
          const invalidSessionError = error.find(
            (e) => _optionalChain([(e ), 'access', _5 => _5.extensions, 'optionalAccess', _6 => _6.code]) === 'SESSION_INVALIDATED'
          )
          if (invalidSessionError) {
            this.invalidateSession(invalidSessionError.message)
          } else {
            _error(new Error(_nullishCoalesce(_optionalChain([error, 'access', _7 => _7[0], 'optionalAccess', _8 => _8.message]), () => ( 'Unknown Error'))))
          }
        } else if (error instanceof CloseEvent) {
          // convert to an error so subscribers only have to worry about handling Error types
          _error(new Error(error.reason))
        } else {
          _error(error)
        }
      }
      sink.next = (value) => {
        const {data} = value
        const subscriptionName = data ? Object.keys(data)[0] : undefined
        const nullObj = this.subscriptionInterfaces[subscriptionName]
        const nextObj =
          nullObj && subscriptionName
            ? {
                ...value,
                data: {
                  ...data,
                  [subscriptionName]: {
                    ...nullObj,
                    ...data[subscriptionName]
                  }
                }
              }
            : value
        _next(nextObj)
      }
      ;(async () => {
        // waiting a tick prevents `client.subscribe` from creating 2 websocket instances
        try {
          await this.connectWebsocket()
        } catch (e) {
          // errors are handled in the closed handler
          return
        }

        const unsubscribe = this.subscriptionClient.subscribe(
          {
            operationName: operation.name,
            query: '',
            docId: operation.id,
            variables
          } ,
          sink 
        )
        if (operation.operationKind === 'subscription') {
          const subKey = Atmosphere.getKey(operation.name, variables)
          this.subscriptions[subKey] = {unsubscribe}
        }
      })()
    })
  }}

  __init14() {this.fetchQuery = async ( 
    taggedNode,
    variables = {},
    cacheConfig



  ) => {
    try {
      const res = await fetchQuery(
        this,
        taggedNode,
        variables,
        _nullishCoalesce(cacheConfig, () => ( {
          fetchPolicy: 'store-or-network'
        }))
      ).toPromise()
      return res
    } catch (e) {
      return e 
    }
  }}
  __init15() {this.getAuthToken = (global) => {
    if (!global) return
    const authToken = global.localStorage.getItem(LocalStorageKey.APP_TOKEN_KEY)
    this.setAuthToken(authToken)
  }}

   __init16() {this.validateImpersonation = async (iat) => {
    const isAnotherParabolTabOpen = async () => {
      const askOtherTabs = new Promise((resolve) => {
        if (!this.tabCheckChannel) {
          this.tabCheckChannel = new BroadcastChannel('tabCheck')
        }
        const tabCheckChannel = this.tabCheckChannel
        tabCheckChannel.onmessage = (event) => {
          if (event.data === 'ping') {
            tabCheckChannel.postMessage('pong')
          }
          if (event.data === 'pong') {
            resolve(true)
          }
        }
        tabCheckChannel.postMessage('ping')
      })
      return Promise.race([sleep(300), askOtherTabs])
    }
    const justOpened = iat > Date.now() / 1000 - 10

    const anotherTabIsOpen = await isAnotherParabolTabOpen()
    if (anotherTabIsOpen || justOpened) return
    this.authToken = null
    this.authObj = null
    window.localStorage.removeItem(LocalStorageKey.APP_TOKEN_KEY)
    // since this is async, useAuthRoute will have already run
    window.location.href = '/'
  }}

  __init17() {this.setAuthToken = async (authToken) => {
    this.authToken = authToken || null
    if (!authToken) {
      this.authObj = null
      window.localStorage.removeItem(LocalStorageKey.APP_TOKEN_KEY)
      return
    }
    try {
      this.authObj = jwtDecode(authToken)
    } catch (e) {
      this.authObj = null
      this.authToken = null
    }

    if (!this.authObj) return
    const {exp, sub: viewerId, rol, iat} = this.authObj
    if (rol === 'impersonate') {
      this.viewerId = viewerId
      return this.validateImpersonation(iat)
    }
    // impersonation token must be < 10 seconds old (ie log them out automatically)
    if (exp < Date.now() / 1000) {
      this.authToken = null
      this.authObj = null
      window.localStorage.removeItem(LocalStorageKey.APP_TOKEN_KEY)
    } else {
      this.viewerId = viewerId
      window.localStorage.setItem(LocalStorageKey.APP_TOKEN_KEY, authToken)
    }
  }}

  __init18() {this.registerQuery = async (
    queryKey,
    subscription,
    variables,
    router
  ) => {
    window.clearTimeout(this.queryTimeouts[queryKey])
    delete this.queryTimeouts[queryKey]
    const {key} = subscription
    // runtime error in case relay changes
    if (!key) throw new Error(`Missing name for sub`)
    const subKey = Atmosphere.getKey(key, variables)
    const isRequested = Boolean(this.querySubscriptions.find((qs) => qs.subKey === subKey))
    if (!isRequested) {
      subscription(this, variables, router)
    }
    this.querySubscriptions.push({queryKey, subKey})
  }}

  registerSubscription(subscriptionRequest) {
    const request = _nullishCoalesce((subscriptionRequest ).default, () => ( subscriptionRequest))
    const payload = request.operation.selections[0] 
    const {selections, name} = payload
    const nullObj = Object.fromEntries(selections.map(({name}) => [name, null]))
    this.subscriptionInterfaces[name] = nullObj
  }
  /*
   * When a subscription encounters an error, it affects the subscription itself,
   * the queries that depend on that subscription to stay valid,
   * and the peer subscriptions that also keep that component valid.
   *
   * For example, in my app component A subscribes to 1,2,3, component B subscribes to 1, component C subscribes to 2,4.
   * If subscription 1 fails, then the data for component A and B get removed from the queryCache, since it might be invalid now
   * Subscription 1 gets unsubscribed,
   * Subscription 2 does not because it is used by component C.
   * Subscription 3 does because no other component depends on it.
   * Subscription 4 does not because there is no overlap
   */
  scheduleUnregisterQuery(queryKey, delay) {
    if (this.queryTimeouts[queryKey]) return
    this.queryTimeouts[queryKey] = window.setTimeout(() => {
      this.unregisterQuery(queryKey)
    }, delay)
  }

  /*
   * removes the query & if the subscription is no longer needed, unsubscribes from it
   */
  unregisterQuery(queryKey) {
    window.clearTimeout(this.queryTimeouts[queryKey])
    delete this.queryTimeouts[queryKey]
    // for each query that is no longer 100% supported, find the subs that power them
    const rowsToRemove = this.querySubscriptions.filter((qs) => qs.queryKey === queryKey)
    // rowsToRemove.forEach((qsToRemove) => {
    // the query is no longer valid, nuke it
    // delete this.queryCache[qsToRemove.queryKey]
    // })
    const subsToRemove = Array.from(new Set(rowsToRemove.map(({subKey}) => subKey)))
    this.querySubscriptions = this.querySubscriptions.filter((qs) => qs.queryKey !== queryKey)
    subsToRemove.forEach((subKey) => {
      const unaffectedSub = this.querySubscriptions.find((qs) => qs.subKey === subKey)
      if (!unaffectedSub) {
        _optionalChain([this, 'access', _9 => _9.subscriptions, 'access', _10 => _10[subKey], 'optionalAccess', _11 => _11.unsubscribe, 'call', _12 => _12()])
      }
    })
  }

  /* When the server wants to end the subscription, it sends a GQL_COMPLETE payload
   * GQL_Trebuchet cleans itself up & calls the onCompleted observer
   * unregisterSub should therefore be called in each subs onCompleted callback
   */
  unregisterSub(name, variables) {
    const subKey = Atmosphere.getKey(name, variables)
    delete this.subscriptions[subKey]
    const rowsToRemove = this.querySubscriptions.filter((qs) => qs.subKey === subKey)
    rowsToRemove.forEach((qsToRemove) => {
      // the query is no longer valid, nuke it
      delete this.queryCache[qsToRemove.queryKey]
    })
    this.querySubscriptions = this.querySubscriptions.filter((qs) => qs.subKey !== subKey)
    // does not remove other subs because they may still do interesting things like pop toasts
  }
  invalidateSession(reason) {
    this.setAuthToken(null)
    this.eventEmitter.emit('addSnackbar', {
      key: 'logOutJWT',
      message: reason,
      autoDismiss: 5
    })
    this.close()
    setTimeout(() => {
      window.location.href = '/'
    }, 5000)
  }
  close() {
    this.querySubscriptions.forEach((querySub) => {
      this.unregisterQuery(querySub.queryKey)
    })
    // remove all records
    ;(this.getStore().getSource() ).clear()
    this.authObj = null
    this.authToken = null
    this.querySubscriptions = []
    this.subscriptions = {}
    this.viewerId = null
    this.connectWebsocketPromise = null
  }
} Atmosphere.__initStatic();
